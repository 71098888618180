import { add, compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { CompanyCostList } from "@/features/modules/companyCostList/objects/CompanyCostList";
import { companyCostListModel } from "@/features/modules/companyCostList/models/CompanyCostListModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { CompanyPriceList } from "@/features/modules/companyPriceList/objects/CompanyPriceList";
import { companyPriceListModel } from "@/features/modules/companyPriceList/models/CompanyPriceListModel";
import { CompanyWithLists } from "@/features/modules/invoice/objects/CompanyWithPriceList";
import { CostList } from "@/features/modules/costList/objects/CostList";
import { CostListItemMode } from "@/features/modules/costList/objects/CostListItemMode";
import { CostListItemType } from "@/features/modules/costList/objects/CostListItemType";
import { costListModel } from "@/features/modules/costList/models/CostListModel";
import { CostListOwnerType } from "@/features/modules/costList/objects/CostListOwnerType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { InvoiceCompanyTotal } from "@/features/modules/invoice/objects/InvoiceCompanyTotal";
import { InvoiceCostRow } from "@/features/modules/invoice/objects/InvoiceCostRow";
import { InvoiceDeadline } from "@/features/modules/invoice/objects/InvoiceDeadline";
import { invoiceModel } from "../InvoiceModel";
import { InvoicePriceRow } from "@/features/modules/invoice/objects/InvoicePriceRow";
import { InvoiceType } from "@/features/modules/invoice/objects/InvoiceType";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedService } from "@/features/modules/service/objects/LinkedService";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { Option } from "@/features/modules/option/objects/Option";
import { optionModel } from "@/features/modules/option/models/OptionModel";
import { PriceList } from "@/features/modules/priceList/objects/PriceList";
import { PriceListItemMode } from "@/features/modules/priceList/objects/PriceListItemMode";
import { PriceListItemType } from "@/features/modules/priceList/objects/PriceListItemType";
import { priceListModel } from "@/features/modules/priceList/models/PriceListModel";
import { ServiceInvoicingType } from "@/features/modules/serviceType/objects/ServiceInvoicingType";
import { TinyLinkedExternalTest } from "@/features/modules/externalTest/objects/TinyLinkedExternalTest";
import { YearlyPaymentType } from "@/features/modules/priceList/objects/YearlyPaymentType";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";

export const calculateInvoiceRows = async (
  invoice: Invoice,
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): Promise<void> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (invoice === undefined) throw new Error("createInvoiceMissingParameter");

    // load option
    const option: Option = await optionModel.getDocument();

    // load companies with price list and cost list
    const companiesWithLists: CompanyWithLists[] = await getCompaniesWithLists(invoice);
    if (companiesWithLists.length === 0) throw new Error("createInvoiceNoCompaniesWithLists");

    const invoiceExaminations: Examination[] = await getInvoiceExaminations(
      invoice.getLinkedExaminations().map((linkedExamination) => linkedExamination.id)
    );

    generateInvoicePriceRows(invoice, companiesWithLists, invoiceExaminations, t, d);

    await handleYearlyPayments(invoice, companiesWithLists, invoiceExaminations, t);

    await handlePreviousDeposit(invoice, t, d);

    await calculateTotals(invoice, companiesWithLists, option);

    generateDeadlines(invoice, companiesWithLists[0].priceList);

    generateInvoiceCostRows(invoice, companiesWithLists, invoiceExaminations, t, d);

    setInvoiceReference(invoice, t, d);
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.calculateInvoiceRows", { invoice, t, d }, error);
  }
};

async function getCompaniesWithLists(invoice: Invoice): Promise<CompanyWithLists[]> {
  const companiesWithLists: CompanyWithLists[] = [];
  if (invoice.company !== undefined) {
    const companyWithLists: CompanyWithLists = await getCompanyWithLists(invoice.company);
    companiesWithLists.push(companyWithLists);
  } else if (invoice.broker !== undefined) {
    const brokerCompanies: Company[] = await companyModel.getCompaniesByBroker(invoice.broker.id);
    for (const brokerCompany of brokerCompanies) {
      let isCompanyActive = false;
      if (invoice.getLinkedExaminations().find((examination) => examination.company?.id === brokerCompany.id) !== undefined) {
        isCompanyActive = true;
      }
      if (invoice.getTinyLinkedExternalTests().find((externalTest) => externalTest.company?.id === brokerCompany.id) !== undefined) {
        isCompanyActive = true;
      }
      if (invoice.getLinkedServices().find((service) => service.company?.id === brokerCompany.id) !== undefined) {
        isCompanyActive = true;
      }
      if (isCompanyActive === false) continue;
      const companyWithLists: CompanyWithLists = await getCompanyWithLists(LinkedCompany.createFromCompany(brokerCompany));
      companiesWithLists.push(companyWithLists);
    }
  }
  return companiesWithLists;
}

async function getCompanyWithLists(linkedCompany: LinkedCompany): Promise<CompanyWithLists> {
  let priceList: PriceList | undefined = undefined;
  const priceLists: CompanyPriceList[] = await companyPriceListModel.getCompanyPriceListsByCompanyAndFirm(linkedCompany.id);
  for (const companyPriceList of priceLists) {
    if (companyPriceList.priceList === undefined) continue;
    if (companyPriceList.isCurrentlyActive() === false) continue;
    priceList = await priceListModel.getDocument(companyPriceList.priceList.id);
    break;
  }
  if (priceList === undefined) throw new Error("createInvoiceNoPriceList");

  let costList: CostList | undefined = undefined;
  const costLists: CompanyCostList[] = await companyCostListModel.getCompanyCostListsByCompanyAndFirm(linkedCompany.id);
  for (const companyCostList of costLists) {
    if (companyCostList.costList === undefined) continue;
    if (companyCostList.isCurrentlyActive() === false) continue;
    costList = await costListModel.getDocument(companyCostList.costList.id);
    break;
  }
  if (costList === undefined) throw new Error("createInvoiceNoCostList");

  return new CompanyWithLists(linkedCompany, priceList, costList);
}

async function getInvoiceExaminations(examinationIds: string[]): Promise<Examination[]> {
  const invoiceExaminations: Examination[] = [];
  while (examinationIds.length > 0) {
    const examinationIdsChunk: string[] = examinationIds.splice(0, 25);
    const examinations: Examination[] = await examinationModel.getExaminationsByIds(examinationIdsChunk);
    invoiceExaminations.push(...examinations);
  }
  return invoiceExaminations;
}

function generateInvoicePriceRows(
  invoice: Invoice,
  companiesWithLists: CompanyWithLists[],
  invoiceExaminations: Examination[],
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): void {
  const invoicePriceRows: InvoicePriceRow[] = [];

  // handle price list items
  for (const companyWithLists of companiesWithLists) {
    // examinations
    const companyInvoiceExaminations: Examination[] = invoiceExaminations.filter(
      (examination) => examination.company?.id === companyWithLists.linkedCompany.id
    );
    // external tests
    const companyInvoiceExternalTests: TinyLinkedExternalTest[] = invoice
      .getTinyLinkedExternalTests()
      .filter((externalTest) => externalTest.company?.id === companyWithLists.linkedCompany.id);
    const companyInvoiceExternalTestsNotBilled: TinyLinkedExternalTest[] = companyInvoiceExternalTests.slice();
    // services
    const companyInvoiceServices: LinkedService[] = invoice
      .getLinkedServices()
      .filter((service) => service.company?.id === companyWithLists.linkedCompany.id);
    const companyInvoiceServicesNotBilled: LinkedService[] = companyInvoiceServices.slice();

    for (const priceListItem of companyWithLists.priceList.prices) {
      const details: string[] = [];
      const detailsPaper: string[] = [];
      let itemCounter = 0;
      if (priceListItem.type === PriceListItemType.ExamType) {
        // price list item is exam type
        for (const examination of companyInvoiceExaminations) {
          if (priceListItem.itemsIds.includes(examination.type?.id as string)) {
            itemCounter++;
            details.push(
              t("invoice.details.examination", {
                type: examination.type?.name ?? t("examination.examination"),
                code: examination.codeDisplay,
                date: d(examination.date, "shortDate"),
                employee: examination.employee?.fullName ?? "-",
              })
            );
            detailsPaper.push(
              t("invoice.details.examinationPaper", {
                type: examination.type?.name ?? t("examination.examination"),
                date: d(examination.date, "shortDate"),
                employee: examination.employee?.fullName ?? "-",
              })
            );
          }
        }
      } else if (priceListItem.type === PriceListItemType.TestType) {
        // price list item is testType
        if (priceListItem.mode === PriceListItemMode.Any) {
          // at least one item must be present
          // examination tests
          for (const examination of companyInvoiceExaminations) {
            const examinationTestTypesIds: string[] = examination
              .getExaminationTests()
              .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
            if (priceListItem.itemsIds.some((itemId) => examinationTestTypesIds.indexOf(itemId) >= 0)) {
              itemCounter++;
              details.push(
                t("invoice.details.testType", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.testTypePaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
          // external tests
          for (const externalTest of companyInvoiceExternalTests.filter((externalTest) => externalTest.supplier !== undefined)) {
            if (priceListItem.itemsIds.includes(externalTest.testType?.id as string)) {
              itemCounter++;
              details.push(
                t("invoice.details.externalTest", {
                  code: externalTest.codeDisplay,
                  date: externalTest.date !== undefined ? d(externalTest.date, "shortDate") : "-",
                  employee: externalTest.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.externalTestPaper", {
                  date: externalTest.date !== undefined ? d(externalTest.date, "shortDate") : "-",
                  employee: externalTest.employee?.fullName ?? "-",
                })
              );
              companyInvoiceExternalTestsNotBilled.splice(companyInvoiceExternalTestsNotBilled.indexOf(externalTest), 1);
            }
          }
        } else if (priceListItem.mode === PriceListItemMode.Group) {
          // a subset of items must be present
          // examination tests
          for (const examination of companyInvoiceExaminations) {
            const examinationTestTypesIds: string[] = examination
              .getExaminationTests()
              .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
            if (DataHelpers.arrayContainsSubset(examinationTestTypesIds, priceListItem.itemsIds)) {
              itemCounter++;
              details.push(
                t("invoice.details.testType", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.testTypePaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
          // external tests
          // group external tests by company and employee, with test type ids
          const externalTestsGrouped: { companyId: string; employee: LinkedEmployee; testTypeIds: string[] }[] = [];
          for (const externalTest of companyInvoiceExternalTests.filter((externalTest) => externalTest.supplier !== undefined)) {
            const externalTestGroup: { companyId: string; employee: LinkedEmployee; testTypeIds: string[] } | undefined = externalTestsGrouped.find(
              (group) => group.companyId === externalTest.company?.id && group.employee.id === externalTest.employee?.id
            );
            if (externalTestGroup === undefined) {
              externalTestsGrouped.push({
                companyId: externalTest.company?.id as string,
                employee: externalTest.employee as LinkedEmployee,
                testTypeIds: [externalTest.testType?.id as string],
              });
            } else {
              externalTestGroup.testTypeIds.push(externalTest.testType?.id as string);
            }
          }
          // check if the group is present
          for (const externalTestGroup of externalTestsGrouped) {
            if (DataHelpers.arrayContainsSubset(externalTestGroup.testTypeIds, priceListItem.itemsIds)) {
              itemCounter++;
              details.push(
                t("invoice.details.groupedExternalTest", {
                  employee: externalTestGroup.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.groupedExternalTest", {
                  employee: externalTestGroup.employee?.fullName ?? "-",
                })
              );
            }
            const companyInvoiceExternalTestsJustBilled: TinyLinkedExternalTest[] = companyInvoiceExternalTestsNotBilled.filter(
              (externalTest) =>
                externalTest.company?.id === externalTestGroup.companyId &&
                externalTest.employee?.id === externalTestGroup.employee.id &&
                externalTestGroup.testTypeIds.includes(externalTest.testType?.id as string)
            );
            for (const externalTestJustBilled of companyInvoiceExternalTestsJustBilled) {
              companyInvoiceExternalTestsNotBilled.splice(companyInvoiceExternalTestsNotBilled.indexOf(externalTestJustBilled), 1);
            }
          }

          /* WIP OLD CODE
          const externalTestsTypesIds: string[] = companyInvoiceExternalTests
            .filter((externalTest) => externalTest.supplier !== undefined)
            .map((externalTest) => externalTest.testType?.id as string);
          if (DataHelpers.arrayContainsSubset(priceListItem.itemsIds, externalTestsTypesIds)) {
            itemCounter++;
            details.push(t("invoice.details.groupedExternalTest"));
            detailsPaper.push(t("invoice.details.groupedExternalTest"));
          }
          */
        } else if (priceListItem.mode === PriceListItemMode.All) {
          // every item must be present
          // examination tests
          for (const examination of companyInvoiceExaminations) {
            const examinationTestTypesIds: string[] = examination
              .getExaminationTests()
              .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
            if (DataHelpers.arrayContainsArray(examinationTestTypesIds, priceListItem.itemsIds)) {
              itemCounter++;
              details.push(
                t("invoice.details.testType", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.testTypePaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
          // external tests
          const externalTestsTypesIds: string[] = companyInvoiceExternalTests
            .filter((externalTest) => externalTest.supplier !== undefined)
            .map((externalTest) => externalTest.testType?.id as string);
          if (DataHelpers.arrayContainsArray(priceListItem.itemsIds, externalTestsTypesIds)) {
            itemCounter++;
            details.push(t("invoice.details.groupedExternalTest"));
            detailsPaper.push(t("invoice.details.groupedExternalTest"));
          }
        }
      } else if (priceListItem.type === PriceListItemType.Survey) {
        // price list item is survey
        if (priceListItem.mode === PriceListItemMode.Any) {
          // at least one item must be present
          for (const examination of companyInvoiceExaminations) {
            const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
            if (priceListItem.itemsIds.some((itemId) => examinationSurveysIds.indexOf(itemId) >= 0)) {
              itemCounter++;
              details.push(
                t("invoice.details.survey", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.surveyPaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
        } else if (priceListItem.mode === PriceListItemMode.Group) {
          // a subset of items must be present
          for (const examination of companyInvoiceExaminations) {
            const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
            if (DataHelpers.arrayContainsSubset(priceListItem.itemsIds, examinationSurveysIds)) {
              itemCounter++;
              details.push(
                t("invoice.details.survey", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.surveyPaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
        } else if (priceListItem.mode === PriceListItemMode.All) {
          // every item must be present
          for (const examination of companyInvoiceExaminations) {
            const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
            if (DataHelpers.arrayContainsArray(priceListItem.itemsIds, examinationSurveysIds)) {
              itemCounter++;
              details.push(
                t("invoice.details.survey", {
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
              detailsPaper.push(
                t("invoice.details.surveyPaper", {
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
        }
      } else if (priceListItem.type === PriceListItemType.ServiceType) {
        // price list item is service type
        for (const service of companyInvoiceServices) {
          if (priceListItem.itemsIds.includes(service.type?.id as string)) {
            if (service.type?.invoicingType === ServiceInvoicingType.ByHour) {
              itemCounter += service.duration;
            } else {
              itemCounter++;
            }
            details.push(
              t("invoice.details.service", {
                type: service.type?.name ?? "-",
                code: service.codeDisplay,
                date: d(service.date, "shortDate"),
                doctor: service.doctor?.fullName ?? "-",
              })
            );
            detailsPaper.push(
              t("invoice.details.servicePaper", {
                type: service.type?.name ?? "-",
                date: d(service.date, "shortDate"),
                doctor: service.doctor?.fullName ?? "-",
              })
            );
            companyInvoiceServicesNotBilled.splice(companyInvoiceServicesNotBilled.indexOf(service), 1);
          }
        }
      }

      // if itemCounter is positive, add the invoice price row
      if (itemCounter > 0) {
        const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
        if (companiesWithLists.length > 1) {
          invoicePriceRow.name = `${companyWithLists.linkedCompany.name} - ${priceListItem.name}`;
        } else {
          invoicePriceRow.name = priceListItem.name;
        }
        invoicePriceRow.price = priceListItem.price;
        invoicePriceRow.isVatApplied = priceListItem.isVatApplied;
        invoicePriceRow.quantity = itemCounter;
        invoicePriceRow.amount = invoicePriceRow.price * invoicePriceRow.quantity;
        invoicePriceRow.companyId = companyWithLists.linkedCompany.id;
        invoicePriceRow.details = details;
        invoicePriceRow.detailsPaper = detailsPaper;
        invoicePriceRows.push(invoicePriceRow);
      }
    }

    // handle external tests without price
    const testTypesIds: string[] = [];
    for (const externalTest of companyInvoiceExternalTestsNotBilled) {
      if (testTypesIds.find((testTypeId) => testTypeId === externalTest.testType?.id) === undefined) {
        testTypesIds.push(externalTest.testType?.id ?? "");

        const selectedExternalTests: TinyLinkedExternalTest[] = companyInvoiceExternalTestsNotBilled.filter(
          (loopExternalTest) => loopExternalTest.testType?.id === externalTest.testType?.id
        );

        const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
        invoicePriceRow.name = t("invoice.testTypeWithoutPrice", { testType: externalTest.testType?.name ?? "-" });
        invoicePriceRow.price = 0;
        invoicePriceRow.isVatApplied = false;
        invoicePriceRow.quantity = selectedExternalTests.length;
        invoicePriceRow.amount = 0;
        invoicePriceRow.companyId = externalTest.company?.id ?? undefined;

        const details: string[] = [];
        const detailsPaper: string[] = [];
        for (const selectedExternalTest of selectedExternalTests) {
          details.push(
            t("invoice.details.testType", {
              code: selectedExternalTest.codeDisplay,
              date: selectedExternalTest.date !== undefined ? d(selectedExternalTest.date, "shortDate") : "-",
              employee: selectedExternalTest.employee?.fullName ?? "-",
            })
          );
          detailsPaper.push(
            t("invoice.details.testTypePaper", {
              date: selectedExternalTest.date !== undefined ? d(selectedExternalTest.date, "shortDate") : "-",
              employee: selectedExternalTest.employee?.fullName ?? "-",
            })
          );
        }

        invoicePriceRow.details = details;
        invoicePriceRow.detailsPaper = detailsPaper;
        invoicePriceRows.push(invoicePriceRow);
      }
    }

    // handle services without price
    const serviceTypesIds: string[] = [];
    for (const service of companyInvoiceServicesNotBilled) {
      if (serviceTypesIds.find((serviceTypeId) => serviceTypeId === service.type?.id) === undefined) {
        serviceTypesIds.push(service.type?.id ?? "");

        const selectedServices: LinkedService[] = companyInvoiceServicesNotBilled.filter((loopService) => loopService.type?.id === service.type?.id);

        const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
        invoicePriceRow.name = t("invoice.serviceWithoutPrice", { service: service.type?.name ?? "-" });
        invoicePriceRow.price = 0;
        invoicePriceRow.isVatApplied = false;
        invoicePriceRow.quantity = selectedServices.length;
        invoicePriceRow.amount = 0;
        invoicePriceRow.companyId = service.company?.id ?? undefined;

        const details: string[] = [];
        const detailsPaper: string[] = [];
        for (const selectedService of selectedServices) {
          details.push(
            t("invoice.details.service", {
              type: selectedService.type?.name ?? "-",
              code: selectedService.codeDisplay,
              date: d(selectedService.date, "shortDate"),
              doctor: selectedService.doctor?.fullName ?? "-",
            })
          );
          detailsPaper.push(
            t("invoice.details.servicePaper", {
              type: selectedService.type?.name ?? "-",
              date: d(selectedService.date, "shortDate"),
              doctor: selectedService.doctor?.fullName ?? "-",
            })
          );
        }

        invoicePriceRow.details = details;
        invoicePriceRow.detailsPaper = detailsPaper;
        invoicePriceRows.push(invoicePriceRow);
      }
    }
  }
  invoice.priceRows = invoicePriceRows;
}

async function handleYearlyPayments(
  invoice: Invoice,
  companiesWithLists: CompanyWithLists[],
  invoiceExaminations: Examination[],
  t: (entry: string, params?: Record<string, unknown>) => string
): Promise<void> {
  invoice.yearlyPaymentsLumpSum = [];
  let yearInvoices: Invoice[] = [];

  for (const companyWithLists of companiesWithLists) {
    if (companyWithLists.priceList.yearlyPaymentType === YearlyPaymentType.LumpSum) {
      const yearInvoices: Invoice[] = await invoiceModel.getInvoicesByYearlyPaymentLumpSum(
        companyWithLists.linkedCompany.id,
        invoice.date.getFullYear()
      );
      if (yearInvoices.length === 0) {
        const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
        if (companiesWithLists.length > 1) {
          invoicePriceRow.name = `${companyWithLists.linkedCompany.name} - ${t("invoice.yearlyPaymentLumpSum")} ${invoice.date.getFullYear()}`;
        } else {
          invoicePriceRow.name = `${t("invoice.yearlyPaymentLumpSum")} ${invoice.date.getFullYear()}`;
        }
        invoicePriceRow.price = companyWithLists.priceList.yearlyPaymentValue;
        invoicePriceRow.isVatApplied = false;
        invoicePriceRow.quantity = 1;
        invoicePriceRow.amount = invoicePriceRow.price * invoicePriceRow.quantity;
        invoicePriceRow.companyId = companyWithLists.linkedCompany.id;
        invoice.priceRows.push(invoicePriceRow);
        invoice.yearlyPaymentsLumpSum.push(companyWithLists.linkedCompany.id);
      }
    } else if (companyWithLists.priceList.yearlyPaymentType === YearlyPaymentType.ByFirstExamination) {
      if (yearInvoices.length === 0) {
        yearInvoices = await invoiceModel.getInvoicesByYear(invoice.date.getFullYear());
      }
      let yearlyPaymentCounter = 0;
      const processedCombinations: { companyId: string; employeeId: string }[] = [];
      for (const invoiceExamination of invoiceExaminations) {
        if (invoiceExamination.company === undefined || invoiceExamination.company.id !== companyWithLists.linkedCompany.id) {
          continue;
        }

        // already processed combination
        if (
          processedCombinations.find(
            (combination) => combination.companyId === invoiceExamination.company?.id && combination.employeeId === invoiceExamination.employee?.id
          ) !== undefined
        ) {
          continue;
        }
        // add combination to processed combinations
        processedCombinations.push({ companyId: invoiceExamination.company?.id as string, employeeId: invoiceExamination.employee?.id as string });
        // find occurrences
        const employeeFilteredInvoices: Invoice[] = yearInvoices.filter(
          (yearInvoice) =>
            yearInvoice
              .getLinkedExaminations()
              .find(
                (linkedExamination) =>
                  linkedExamination.company?.id === invoiceExamination.company?.id &&
                  linkedExamination.employee?.id === invoiceExamination.employee?.id
              ) !== undefined
        );
        if (employeeFilteredInvoices.length === 0) {
          yearlyPaymentCounter++;
        }
      }

      if (yearlyPaymentCounter > 0) {
        const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
        if (companiesWithLists.length > 1) {
          // eslint-disable-next-line prettier/prettier
          invoicePriceRow.name = `${companyWithLists.linkedCompany.name} - ${t("invoice.yearlyPaymentByFirstExamination")} ${invoice.date.getFullYear()}`;
        } else {
          invoicePriceRow.name = `${t("invoice.yearlyPaymentByFirstExamination")} ${invoice.date.getFullYear()}`;
        }
        invoicePriceRow.price = companyWithLists.priceList.yearlyPaymentValue;
        invoicePriceRow.isVatApplied = false;
        invoicePriceRow.quantity = yearlyPaymentCounter;
        invoicePriceRow.amount = invoicePriceRow.price * invoicePriceRow.quantity;
        invoicePriceRow.companyId = companyWithLists.linkedCompany.id;
        invoice.priceRows.push(invoicePriceRow);
      }
    }
  }
}

async function handlePreviousDeposit(
  invoice: Invoice,
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): Promise<void> {
  if (invoice.company !== undefined) {
    const lastInvoice: Invoice | undefined = await invoiceModel.getLastInvoiceByCompany(invoice.company.id);
    if (lastInvoice !== undefined && lastInvoice.type === InvoiceType.Deposit) {
      const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
      invoicePriceRow.name = t("invoice.depositRow", { code: lastInvoice.codeDisplay, date: d(lastInvoice.date, "shortDate") }).toLocaleUpperCase();
      invoicePriceRow.price = lastInvoice.subtotal * -1;
      invoicePriceRow.isVatApplied = true;
      invoicePriceRow.quantity = 1;
      invoicePriceRow.amount = invoicePriceRow.price * invoicePriceRow.quantity;
      invoice.priceRows.push(invoicePriceRow);
    }
  } else if (invoice.broker !== undefined) {
    const lastInvoice: Invoice | undefined = await invoiceModel.getLastInvoiceByBroker(invoice.broker.id);
    if (lastInvoice !== undefined && lastInvoice.type === InvoiceType.Deposit) {
      const invoicePriceRow: InvoicePriceRow = new InvoicePriceRow();
      invoicePriceRow.name = t("invoice.depositRow", { code: lastInvoice.codeDisplay, date: d(lastInvoice.date, "shortDate") }).toLocaleUpperCase();
      invoicePriceRow.price = lastInvoice.subtotal * -1;
      invoicePriceRow.isVatApplied = true;
      invoicePriceRow.quantity = 1;
      invoicePriceRow.amount = invoicePriceRow.price * invoicePriceRow.quantity;
      invoice.priceRows.push(invoicePriceRow);
    }
  }
}

async function calculateTotals(invoice: Invoice, companiesWithLists: CompanyWithLists[], option: Option): Promise<void> {
  const firm: Firm = await firmModel.getSelectedFirm();

  // invoice rows
  invoice.subtotalVatExempted = 0; // imponibile esente IVA
  invoice.subtotalVatSubjected = 0; // imponibile soggetto IVA
  invoice.vat = 0; // iva
  invoice.emptyCompanyTotals();

  for (const invoicePriceRow of invoice.priceRows) {
    let subtotalVatSubjected = 0;
    let subtotalVatExempted = 0;
    if (invoicePriceRow.isVatApplied === true) {
      subtotalVatSubjected = invoicePriceRow.amount;
    } else {
      subtotalVatExempted = invoicePriceRow.amount;
    }
    invoice.subtotalVatExempted += subtotalVatExempted;
    invoice.subtotalVatSubjected += subtotalVatSubjected;
    if (invoicePriceRow.companyId !== undefined) {
      if (invoicePriceRow.companyId in invoice.companyTotals === false) {
        invoice.companyTotals[invoicePriceRow.companyId] = new InvoiceCompanyTotal(invoicePriceRow.companyId);
      }
      invoice.companyTotals[invoicePriceRow.companyId].subtotalVatExempted += subtotalVatExempted;
      invoice.companyTotals[invoicePriceRow.companyId].subtotalVatSubjected += subtotalVatSubjected;
    }
  }

  // totals
  invoice.subtotal = invoice.subtotalVatExempted + invoice.subtotalVatSubjected;
  for (const invoiceCompanyTotal of invoice.getCompanyTotals()) {
    invoiceCompanyTotal.subtotal = invoiceCompanyTotal.subtotalVatExempted + invoiceCompanyTotal.subtotalVatSubjected;
    const companyWithLists: CompanyWithLists | undefined = companiesWithLists.find(
      (companyWithLists) => companyWithLists.linkedCompany.id === invoiceCompanyTotal.companyId
    );
    if (companyWithLists === undefined) throw new Error("createInvoiceNoCompanyWithLists");
    // discount
    invoiceCompanyTotal.discount = DataHelpers.roundNumber(invoice.subtotal * (companyWithLists.priceList.discountValue / 100), 2);
    invoice.discount += invoiceCompanyTotal.discount;
    const discountVatExempted: number = (invoiceCompanyTotal.discount * invoiceCompanyTotal.subtotalVatExempted) / invoice.subtotal;
    const discountVatSubjected: number = (invoiceCompanyTotal.discount * invoiceCompanyTotal.subtotalVatSubjected) / invoice.subtotal;

    invoiceCompanyTotal.subtotalVatExempted = Math.max(invoiceCompanyTotal.subtotalVatExempted - discountVatExempted, 0);
    invoiceCompanyTotal.subtotalVatSubjected = Math.max(invoiceCompanyTotal.subtotalVatSubjected - discountVatSubjected, 0);
    invoiceCompanyTotal.subtotal = invoiceCompanyTotal.subtotalVatExempted + invoiceCompanyTotal.subtotalVatSubjected;

    invoiceCompanyTotal.vat = DataHelpers.roundNumber(invoiceCompanyTotal.subtotalVatSubjected * (option.vatValue / 100), 2);
    invoiceCompanyTotal.total = invoiceCompanyTotal.subtotal + invoiceCompanyTotal.vat;
  }

  const discountVatExempted: number = (invoice.discount * invoice.subtotalVatExempted) / invoice.subtotal;
  const discountVatSubjected: number = (invoice.discount * invoice.subtotalVatSubjected) / invoice.subtotal;

  invoice.subtotalVatExempted = Math.max(invoice.subtotalVatExempted - discountVatExempted, 0);
  invoice.subtotalVatSubjected = Math.max(invoice.subtotalVatSubjected - discountVatSubjected, 0);
  invoice.subtotal = invoice.subtotalVatExempted + invoice.subtotalVatSubjected;

  invoice.vat = DataHelpers.roundNumber(invoice.subtotalVatSubjected * (option.vatValue / 100), 2);
  invoice.total = invoice.subtotal + invoice.vat;

  // stamp
  invoice.stamp = 0;
  // && invoice.subtotalVatSubjected === 0) {
  if (invoice.subtotalVatExempted >= option.stampThreshold) {
    invoice.stamp = option.stampValue;
  }

  // withHoldingTax
  invoice.withHoldingTax = 0;
  if (firm.hasWithHoldingTax === true) {
    invoice.withHoldingTax = DataHelpers.roundNumber(invoice.subtotalVatExempted * (option.withHoldingTaxValue / 100), 2);
  }
  invoice.total += invoice.stamp - invoice.withHoldingTax;
}

function generateDeadlines(invoice: Invoice, priceList: PriceList): void {
  for (const payment of priceList.payments) {
    const deadline: InvoiceDeadline = new InvoiceDeadline();
    deadline.date = add(invoice.date, { days: payment.daysToExpiration });
    deadline.daysToExpiration = payment.daysToExpiration;
    deadline.amount = DataHelpers.roundNumber(invoice.total * (payment.amount / 100), 2);
    deadline.amountPercentage = payment.amount;
    invoice.deadlines.push(deadline);
  }
  // fix last deadline if total is different from sum of deadlines
  if (invoice.total != invoice.deadlines.reduce((acc, deadline) => acc + deadline.amount, 0)) {
    const lastDeadline: InvoiceDeadline = invoice.deadlines[invoice.deadlines.length - 1];
    lastDeadline.amount += invoice.total - invoice.deadlines.reduce((acc, deadline) => acc + deadline.amount, 0);
  }
}

function generateInvoiceCostRows(
  invoice: Invoice,
  companiesWithLists: CompanyWithLists[],
  invoiceExaminations: Examination[],
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): void {
  const invoiceCostRows: InvoiceCostRow[] = [];

  // handle cost list items
  for (const companyWithLists of companiesWithLists) {
    const companyInvoiceExaminations: Examination[] = invoiceExaminations.filter(
      (examination) => examination.company?.id === companyWithLists.linkedCompany.id
    );

    for (const costListItem of companyWithLists.costList.costs) {
      const details: string[] = [];
      let itemCounter = 0;
      if (costListItem.type === CostListItemType.ExamType) {
        // owner is doctor
        if (costListItem.ownerType === CostListOwnerType.Doctor) {
          // cost list item is exam type
          for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
            if (costListItem.itemsIds.includes(examination.type?.id as string)) {
              itemCounter++;
              details.push(
                t("invoice.details.examination", {
                  type: examination.type?.name ?? t("examination.examination"),
                  code: examination.codeDisplay,
                  date: d(examination.date, "shortDate"),
                  employee: examination.employee?.fullName ?? "-",
                })
              );
            }
          }
        }
      } else if (costListItem.type === CostListItemType.TestType) {
        // cost list item is testType
        if (costListItem.mode === CostListItemMode.Any) {
          // at least one item must be present
          // examination tests
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationTestTypesIds: string[] = examination
                .getExaminationTests()
                .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
              if (costListItem.itemsIds.some((itemId) => examinationTestTypesIds.indexOf(itemId) >= 0)) {
                itemCounter++;
                details.push(
                  t("invoice.details.testType", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
          // external tests
          // owner is supplier
          if (costListItem.ownerType === CostListOwnerType.Supplier) {
            for (const externalTest of invoice
              .getTinyLinkedExternalTests()
              .filter((externalTest) => externalTest.supplier?.id === costListItem.ownerId)) {
              if (costListItem.itemsIds.includes(externalTest.testType?.id as string)) {
                itemCounter++;
                details.push(
                  t("invoice.details.externalTest", {
                    code: externalTest.codeDisplay,
                    date: externalTest.date !== undefined ? d(externalTest.date, "shortDate") : "-",
                    employee: externalTest.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
        } else if (costListItem.mode === CostListItemMode.Group) {
          // a subset of items must be present
          // examination tests
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationTestTypesIds: string[] = examination
                .getExaminationTests()
                .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
              if (DataHelpers.arrayContainsSubset(costListItem.itemsIds, examinationTestTypesIds)) {
                itemCounter++;
                details.push(
                  t("invoice.details.testType", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
          // external tests
          // owner is supplier
          if (costListItem.ownerType === CostListOwnerType.Supplier) {
            const externalTestsTypesIds: string[] = invoice
              .getTinyLinkedExternalTests()
              .filter((externalTest) => externalTest.supplier?.id === costListItem.ownerId)
              .map((externalTest) => externalTest.testType?.id as string);
            if (DataHelpers.arrayContainsArray(costListItem.itemsIds, externalTestsTypesIds)) {
              itemCounter++;
              details.push(t("invoice.details.groupedExternalTest"));
            }
          }
        } else if (costListItem.mode === CostListItemMode.All) {
          // every item must be present
          // examination tests
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationTestTypesIds: string[] = examination
                .getExaminationTests()
                .map((examinationTest) => examinationTest.testType?.id ?? "NIL");
              if (DataHelpers.arrayContainsArray(costListItem.itemsIds, examinationTestTypesIds)) {
                itemCounter++;
                details.push(
                  t("invoice.details.testType", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
          // external tests
          // owner is supplier
          if (costListItem.ownerType === CostListOwnerType.Supplier) {
            const externalTestsTypesIds: string[] = invoice
              .getTinyLinkedExternalTests()
              .filter((externalTest) => externalTest.supplier?.id === costListItem.ownerId)
              .map((externalTest) => externalTest.testType?.id as string);
            if (DataHelpers.arrayContainsArray(costListItem.itemsIds, externalTestsTypesIds)) {
              itemCounter++;
              details.push(t("invoice.details.groupedExternalTest"));
            }
          }
        }
      } else if (costListItem.type === CostListItemType.Survey) {
        // cost list item is survey
        if (costListItem.mode === CostListItemMode.Any) {
          // at least one item must be present
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
              if (costListItem.itemsIds.some((itemId) => examinationSurveysIds.indexOf(itemId) >= 0)) {
                itemCounter++;
                details.push(
                  t("invoice.details.survey", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
        } else if (costListItem.mode === CostListItemMode.Group) {
          // a subset of items must be present
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
              if (DataHelpers.arrayContainsSubset(costListItem.itemsIds, examinationSurveysIds)) {
                itemCounter++;
                details.push(
                  t("invoice.details.survey", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
        } else if (costListItem.mode === CostListItemMode.All) {
          // every item must be present
          // owner is doctor
          if (costListItem.ownerType === CostListOwnerType.Doctor) {
            for (const examination of companyInvoiceExaminations.filter((examination) => examination.doctor?.id === costListItem.ownerId)) {
              const examinationSurveysIds: string[] = examination.getLinkedSurveys().map((survey) => survey.id);
              if (DataHelpers.arrayContainsArray(costListItem.itemsIds, examinationSurveysIds)) {
                itemCounter++;
                details.push(
                  t("invoice.details.survey", {
                    code: examination.codeDisplay,
                    date: d(examination.date, "shortDate"),
                    employee: examination.employee?.fullName ?? "-",
                  })
                );
              }
            }
          }
        }
      } else if (costListItem.type === CostListItemType.ServiceType) {
        // cost list item is service type
        if (costListItem.ownerType === CostListOwnerType.Doctor) {
          for (const service of invoice.getLinkedServices().filter((service) => service.doctor?.id === costListItem.ownerId)) {
            if (costListItem.itemsIds.includes(service.type?.id as string)) {
              if (service.type?.invoicingType === ServiceInvoicingType.ByHour) {
                itemCounter += service.duration;
              } else {
                itemCounter++;
              }
              details.push(
                t("invoice.details.service", {
                  type: service.type?.name ?? "-",
                  code: service.codeDisplay,
                  date: d(service.date, "shortDate"),
                  doctor: service.doctor?.fullName ?? "-",
                })
              );
            }
          }
        }
      }

      // if itemCounter is positive, add the invoice cost row
      if (itemCounter > 0) {
        const invoiceCostRow: InvoiceCostRow = new InvoiceCostRow();
        if (companiesWithLists.length > 1) {
          invoiceCostRow.name = `${companyWithLists.linkedCompany.name} - ${costListItem.name}`;
        } else {
          invoiceCostRow.name = costListItem.name;
        }
        invoiceCostRow.ownerType = costListItem.ownerType;
        invoiceCostRow.ownerId = costListItem.ownerId;
        invoiceCostRow.price = costListItem.price;
        invoiceCostRow.isVatApplied = costListItem.isVatApplied;
        invoiceCostRow.quantity = itemCounter;
        invoiceCostRow.amount = invoiceCostRow.price * invoiceCostRow.quantity;
        invoiceCostRow.companyId = companyWithLists.linkedCompany.id;
        invoiceCostRow.details = details;
        invoiceCostRows.push(invoiceCostRow);
      }
    }
  }
  invoice.costRows = invoiceCostRows;
}

function setInvoiceReference(
  invoice: Invoice,
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): void {
  const itemsArray: string[] = [];
  let startDate: Date | undefined = undefined;
  let endDate: Date | undefined = undefined;
  if (invoice.getLinkedExaminations().length > 0) {
    itemsArray.push(t("examination.examinations"));
    for (const examination of invoice.getLinkedExaminations()) {
      if (startDate === undefined || compareAsc(startDate, examination.date) > 0) {
        startDate = new Date(examination.date.getTime());
      }
      if (endDate === undefined || compareAsc(examination.date, endDate) > 0) {
        endDate = new Date(examination.date.getTime());
      }
    }
  }
  if (invoice.getTinyLinkedExternalTests().length > 0) {
    itemsArray.push(t("externalTest.externalTests"));
    for (const externalTest of invoice.getTinyLinkedExternalTests()) {
      if (externalTest.date === undefined) continue;
      if (startDate === undefined || compareAsc(startDate, externalTest.date) > 0) {
        startDate = new Date(externalTest.date.getTime());
      }
      if (endDate === undefined || compareAsc(externalTest.date, endDate) > 0) {
        endDate = new Date(externalTest.date.getTime());
      }
    }
  }
  if (invoice.getLinkedServices().length > 0) {
    itemsArray.push(t("service.services"));
    for (const service of invoice.getLinkedServices()) {
      if (startDate === undefined || compareAsc(startDate, service.date) > 0) {
        startDate = new Date(service.date.getTime());
      }
      if (endDate === undefined || compareAsc(service.date, endDate) > 0) {
        endDate = new Date(service.date.getTime());
      }
    }
  }
  invoice.reference = itemsArray.join(", ");
  // replace last occurrence of ", " with " e "
  invoice.reference = invoice.reference.replace(/,([^,]*)$/, " e$1");
  if (startDate !== undefined && endDate !== undefined) {
    invoice.reference += ` ${t("invoice.from")} ${d(startDate, "shortDate")} ${t("invoice.to")} ${d(endDate, "shortDate")}`;
  }
}
